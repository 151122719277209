import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import * as styles from "./index.module.css";
import { Link } from "gatsby";



export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu - zadania i zdarzenia"
      keywords={["Zarządzanie rozproszonym zespołem sprzedażowym"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu"
      metaTitle="Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu"
      metaDescription="Zarządzanie rozproszonym zespołem sprzedażowym w CRM - pierwsza część Vloga o tym, 
      jak skutecznie zarządzać zdalnym zespołem sprzedażowym"
    >
      <br />
      <p>
        Witamy serdecznie w naszej nowej serii materiałów video, w której
        poruszamy temat zarządzania rozproszonymi zespołami sprzedażowymi.
        Opowiadamy w nich o tym, jak skutecznie zarządzać sprzedażą i
        koordynować pracę zespołu w sytuacjach, gdy mamy utrudniony bezpośredni
        kontakt ze współpracownikami. Mamy nadzieję, że te publikacje pomogą Wam
        jak najlepiej wykorzystywać nasze systemy w czasie kryzysu.
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/jeKe1Ur180s"
        allowFullScreen
        ratio="16:9"
      />
      <p>
        W pierwszym odcinku naszego nowego vloga Wojciech Nosal pochyla się nad
        tematem zarządzania zadaniami i zleceniami pracowników w rozproszonym
        zespole sprzedażowym. Z poniższego materiału dowiesz się, jak:
      </p>
      <p>
        <ul>
          <li>pracować na zdarzeniach i zadaniach,</li>
          <li>
            działają zdarzenia i zadania w kontekście kartoteki kontrahenta,
          </li>
          <li>konfigurować przypomnienia,</li>
          <li>efektywnie wykorzystywać współdzielenie kalendarza,</li>
          <li>usprawnić niebezpośrednią komunikację.</li>
        </ul>
      </p>
      <br />
      <p>
        Zapraszamy również do kolejnych filmów z tej serii. Wierzymy bowiem, że
        praca zdalna przy użyciu nowoczesnych technologii może być efektywna. Z
        chęcią pokażemy Ci, jak to osiągnąć dzięki naszemu oprogramowaniu.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz umiejętnie zarządzać rozproszonymi zespołami sprzedażowymi?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać tajemnicę sukcesu
            </Link>
          </div>
          </div>
    </section>
    </Artykul>
  );
};

export default Specrm;
